@import '../../_colors.scss';

.single-video-wrapper {
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .video-list-wrapper {
    width: 60%;
    display: block;
    background-color: #343434;
    text-align: center;
    border: 1px solid #000;
    margin: 20px;
    padding: 10px;
    border-radius: 25px;
  }

  .video-img {
    background-size: 210px 210px;
    background-repeat: no-repeat;
    background-position: center;
    width: 210px;
    height: 160px;
    margin: 0 20px;
  }

  .video-img, .video-description, .video-title {
    margin: 10px 0;
  }

  .video-description {
    text-transform: initial;

    a {
      color: red;
      text-decoration: none;
      font-weight: 700;
    }
  }
}