@import '../../_colors.scss';

.list-video-wrapper {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.loader-lazy-load {
    width: 100%;
    height: 150px;
}

.video-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 350px;
    text-decoration: none;
    color: #000;
    padding: 5px 0;
    background-color: #343434;
    text-align: center;
    border: 1px solid $main_color;
    border-radius: 25px;
    position: relative;

    .download {
        border-radius: 15px;
        background: #2e3091;
        padding: 12px 80px;
        border-width: 0;
        color: #fff;
        display: block;
        text-decoration: none;
        width: 40%;
        /* margin: 0 auto; */
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 20px;
        text-align: center;
    }

    .video-right-block {
        .video-title {
            padding: 0 20px;
            font-size: 17px;
            font-weight: 700;
            color: #fff;
        }

        .video-description {
            padding: 10px 20px 0 20px;
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;
            letter-spacing: 2px;
            font-size: 13px;
        }

        .video-downloads {
            padding-right: 20px;
            padding-top: 10px;
            text-transform: uppercase;
            font-weight: 700;
            color: #d0d0d0;
            letter-spacing: 2px;
            font-size: 13px;
            text-align: center;
        }
    }

    .video-left-block .video-img {
        background-size: 110px 110px;
        background-repeat: no-repeat;
        background-position: center;
        width: 110px;
        height: 80px;
        margin: 0 auto;
    }
}

.single-video-wrapper .video-list-wrapper {
    height: initial;
}

@media screen and (max-width: 1300px) {
    .list-video-wrapper {
        width: 80%;
    }
    .video-list-wrapper {
        margin: 10px 0;

        .download {
            padding: 12px 20px;
        }
    }
}

@media screen and (min-width: 1023px) {
    .list-video-wrapper {
        text-align: center;
    }

    .video-list-wrapper {
        width: 20vw;
        display: inline-block;
        background-color: #1b1c1c;
        text-align: center;
        border: 1px solid #000;
        margin: 10px;
        padding: 10px;
        border-radius: 25px;
        position: relative;
    }

    .video-left-block {
        display: flex;
        justify-content: center;
    }

    .video-list-wrapper {
        .video-left-block .video-img {
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            width: 230px;
            height: 127px;
            margin: 20px;
        }

        .video-right-block .video-title {
            padding-right: initial;
        }
    }
}

@media screen and (max-width: 1000px) {
    .video-description {
        font-size: 8px !important;
    }
    .video-left-block {
        margin: 10px 0;
    }
}
